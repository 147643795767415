import {
   getAdditionalUserInfo,
   getAuth,
   signInWithEmailAndPassword,
} from "firebase/auth";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase";
import Logo from "../components/icons/Logo";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function Login() {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");

   const nav = useNavigate();
   const [error, setError] = useState("");
   const [loading, setLoading] = useState(false);
   const handleSubmit = async (e) => {
      setLoading(true);
      e.preventDefault();
      try {
         await signInWithEmailAndPassword(auth, email, password);
         nav("/");
      } catch (err) {
         setError(err.message);
      } finally {
         setLoading(false);
      }
   };
   return (
      <>
         {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-900">
        <body class="h-full">
        ```
      */}
         <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8  bg-gradient-to-r from-black to-gray-900">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col items-center">
               <Logo size={130} className="bg-white" />
               <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                  Sign in to your account
               </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
               {error && (
                  <div className="text-red-500 text-sm text-left mb-3">
                     {error}
                  </div>
               )}
               <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                     <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-white"
                     >
                        Email address
                     </label>
                     <div className="mt-2">
                        <input
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           id="email"
                           name="email"
                           type="email"
                           required
                           autoComplete="email"
                           className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-theme-secondary sm:text-sm sm:leading-6"
                        />
                     </div>
                  </div>

                  <div>
                     <div className="flex items-center justify-between">
                        <label
                           htmlFor="password"
                           className="block text-sm font-medium leading-6 text-white"
                        >
                           Password
                        </label>
                     </div>
                     <div className="mt-2">
                        <input
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           id="password"
                           name="password"
                           type="password"
                           required
                           autoComplete="current-password"
                           className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-theme-secondary sm:text-sm sm:leading-6"
                        />
                     </div>
                  </div>

                  <div>
                     <button
                        type="submit"
                        className="flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-theme-secondary hover:bg-theme-secondary-light transition-colors duration-150  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-secondary"
                     >
                        {loading ? "Logging in..." : "Log in"}
                     </button>
                  </div>
               </form>

               <p className="mt-10 text-center text-sm text-gray-400">
                  Not a member?{" "}
                  <Link
                     to="/signup"
                     href="#"
                     className="font-semibold leading-6 text-theme-third "
                  >
                     Create an account
                  </Link>
               </p>
            </div>
         </div>
      </>
   );
}
