import Reveal from "../shared/Reveal";

const people = [{"title": "DIE WITH A SMILE", "artist": "LADY GAGA, BRUNO MARS", "image": "https://resources.tidal.com/images/ea1b873b/1bee/4b73/a894/078d60c96498/320x320.jpg", "week": 1, "lastWeek": 25}, {"title": "TASTE", "artist": "SABRINA CARPENTER", "image": "https://resources.tidal.com/images/9255f94e/dffa/46ee/93fd/d940b6c85a93/320x320.jpg", "week": 2, "lastWeek": 22}, {"title": "NEVA PLAY (FEAT. RM OF BTS)", "artist": "MEGAN THEE STALLION", "image": "https://resources.tidal.com/images/782f6121/6a3d/4f68/bf3b/2f052ada9854/320x320.jpg", "week": 23, "lastWeek": 23}]

export default function Chart() {
  return (
    <div className=" w-full mt-3 relative">
      <div className="flex flex-col items-start h-full  shadow-md rounded-md overflow-hidden w-full">
        <div className="bg-[#2b0b3d] border-b-2 px-4 py-3 w-full">
          <div className="flex justify-between items-center uppercase ">
            <div className="flex space-x-3">
              <p className="text-sm font-semibold text-white">Week</p>
              <p className="text-sm font-semibold text-white">Name</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-white">Last Week</p>
            </div>
          </div>
        </div>

        <ul className="divide-y divide-gray-700 overflow-y-auto h-80 sm:h-full max-h-[518px] w-full overflow-x-hidden scroll-design">
          {people.map((person, index) => (
            <Reveal key={index}
              hidden={{
                opacity: 0,
                x: index % 2 === 0 ? -75 : 75,
              }}
              visible={{ opacity: 1, x: 0 }}>
              <li
                key={index}
                className={`flex justify-between p-3 hover:bg-purple-200 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
              >
                <div className="flex items-center gap-3">
                  <p className="text-sm p-2 m-1 font-medium text-white bg-theme-third rounded-full h-[40px] w-[40px] flex items-center justify-center">
                    {String(person.week).padStart(2, "0")}
                  </p>
                  <img
                    className="h-10 w-10 m-1 rounded-full"
                    src={person.image}
                    alt=""
                  />
                  <div className="flex flex-col items-start">
                    <p className="text-sm font-medium text-gray-800">
                      {person.title}
                    </p>
                    <p className="text-sm text-gray-600">{person.artist}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm font-medium text-gray-500 ml-2">
                    {person.lastWeek}
                  </p>
                </div>
              </li>
            </Reveal>
          ))}
        </ul>
      </div>
    </div >

  );
}
