import React from "react";
import Chart from "./chart";
import Reveal from "../shared/Reveal";

function Live() {
  return (
    <div className="bg-white relative">
      <div class="custom-shape-divider-top-1709652370 md:block hidden">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
        </svg>
      </div>
      <section className="min-h-full max-w-7xl mx-auto md:pb-[200px] relative z-20 flex flex-col md:flex-row justify-center items-start md:p-14 md:px-24 text-theme-primary">
        <div className="w-full h-full min-h-[600px] p-4 pb-0 md:px-14 text-theme-third flex flex-col items-start">
          <Reveal hidden={{ opacity: 0, x: -75 }}
            visible={{ opacity: 1, x: 0 }} transition={{ duration: 0.75, delay: 0.25 }}>
            <h2 className="text-gray-800 text-3xl font-black">UPDATED</h2>
          </Reveal>
          <div className="flex items-center mb-12">
            <Reveal hidden={{ opacity: 0, x: -75 }}
              visible={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.50 }}>
              <p>Sundays at 4pm CET</p>
            </Reveal>
            <Reveal hidden={{ opacity: 0, scale: 0 }}
              visible={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5, delay: 0.50 }}>
              <div className="w-32 h-[1px] bg-theme-third mx-5">
                <div className="w-1/2 h-full bg-gray-800"></div>
              </div>
            </Reveal>
            <Reveal hidden={{ opacity: 0, x: 75 }}
              transition={{ duration: 0.5, delay: 0.50 }}
              visible={{ opacity: 1, x: 0 }}>
              <p>7pm CET</p>
            </Reveal>
          </div>

          <Reveal hidden={{ opacity: 0, scale: 0, y: 100 }}
            visible={{ opacity: 1, scale: 1, y: 0 }}>
            <h5 className="uppercase text-gray-800 font-semibold">AI HOST</h5>
            <p className="text-3xl font-black">NEXUS JAMES</p>
          </Reveal>
          <Chart />
        </div>
      </section>
      <div class="custom-shape-divider-bottom-1709652370 md:block hidden">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
        </svg>
      </div>
    </div>

  );
}

export default Live;
