import {getDownloadURL, ref} from "firebase/storage";
import classNames from "classnames";
import {useEffect, useState} from "react";
import {storage} from "../utils/firebase";
import useAuth from "../hooks/useAuth";
import Logo from "../components/icons/Logo";
import Navbar from "../components/Navbar";
import {useNavigate} from "react-router-dom";


// THESE SHOULD BE URLS COMING FROM FIREBASE FIRESTORE
// EXAMPLE: https://firebasestorage.googleapis.com/v0/b/aitop40-stripe.appspot.com/o/song1.mp3?alt=media&token=ca03cb6d-d3cf-4259-89a7-1c2816ff0715

const songs = [
  {key: "/song1.mp3", name: "AIT40", singer: "PART1"},
  {key: "/song2.mp3", name: "AIT40", singer: "PART2"},
];
export default function ClientArea() {
  const [loading, setLoading] = useState(-1);
  const {paid} = useAuth();
  const navigate = useNavigate();
  useEffect(() => {}, []);
  const handleDownload = async (item, index) => {
    setLoading(index);
    try {
      const fileRef = ref(storage, item.key); // Update with the actual path

      // Get the download URL
      const url = await getDownloadURL(fileRef);

      // Fetch the file content as a blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a link element, use the blob URL as the href, and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", item.key.slice(1)); // Name the file to be downloaded
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM
      window.URL.revokeObjectURL(link.href);

      // Or inserted into an <img> element
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(-1);
    }
  };
  if (!paid) {
    return (
      <>
        <Navbar></Navbar>
        <div className="flex flex-col gap-10  justify-center items-center w-full h-full bg-gradient-to-r from-black to-gray-900">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm flex items-center flex-col">
            <Logo size={130} className=" bg-theme-secondary" />
          </div>
          <h2 className="text-4xl font-semibold mb-20 text-white text-center">You need to buy the subscription from the home page.</h2>
          <button
            className={classNames(
              "flex  justify-center rounded-md w-fit bg-theme-secondary hover:bg-theme-secondary-light transition-colors duration-150 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-secondary"
            )}
            onClick={() => navigate("/")}>
            Home Page
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar></Navbar>
      <div className="flex flex-col  justify-center items-center w-full h-full bg-gradient-to-r from-black to-gray-900 ">
        <div className="flex flex-col w-1/2 gap-6">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm flex items-center flex-col">
            <Logo size={130} className=" bg-theme-secondary" />
          </div>
          <h2 className="text-4xl font-semibold mb-8 text-white ">Download the AI Top40 program segments from below</h2>
          <div className="flex flex-col  shadow-lg border border-theme-accent rounded-2xl  px-7 py-3 ">
            {songs.map((song, i) => (
              <div
                className={classNames("flex w-full justify-between items-center border-b border-theme-accent p-2 py-5", {
                  "border-none": i === songs.length - 1,
                })}>
                <div className="flex flex-col gap-1 text-white whitespace-nowrap">
                  <div className="font-bold text-lg">{song.name}</div>
                  <div className="text-sm">{song.singer}</div>
                </div>
                <button
                  type="submit"
                  className={classNames(
                    "flex  justify-center rounded-md w-fit bg-theme-secondary hover:bg-theme-secondary-light transition-colors duration-150 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-secondary"
                  )}
                  onClick={() => handleDownload(song, i)}>
                  {loading === i ? "Downloading..." : "Download"}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
