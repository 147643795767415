import sallyImg from "../assets/backgrounds/carousel1.jpg";
import dannyImg from "../assets/backgrounds/carousel2.jpg";
import { Carousel } from "flowbite-react";
import { useState } from "react";
import ListenLive from "./ListenLive";

export default function HeaderCarousel({ togglePlay, playAudio }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const headerText = ["LADY GAGA, BRUNO MARS #1 ON THE CHART", "NEXUS JAMES AI"];
  const headerTranslate = "-translate-y-7 opacity-100";

  function handleSlideChange(slideIdx) {
    setSlideIndex(slideIdx);
  }

  function HeaderTextElement(currentSlideIndex) {
    return (
      <span
        className={`absolute bottom-0 left-0 pl-4 opacity-0 ${slideIndex == currentSlideIndex ? headerTranslate : ""
          } font-bold text-3xl md:text-4xl isolate z-20
    max-w-[27rem] transition ease-in duration-100`}
      >
        {headerText[currentSlideIndex]}
      </span>
    );
  }

  function CaroudelImage({ src, alt }) {
    return (
      <div className="relative w-full h-full">
        <img
          src={src}
          alt={alt}
          className="absolute top-0 left-0 object-cover object-center w-full h-full"
        />
      </div>
    );
  }

  return (
    <div className="w-[min(90%,900px)] sm:w-[min(90%,500px)] md:w-[min(90%,450px)] lg:w-[min(90%,700px)]  h-[min(100%,300px)] md:h-[min(100%,400px)] z-10 text-white text-4xl font-bold uppercase absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <ListenLive togglePlay={togglePlay} playAudio={playAudio}  />

      {/* header text */}
      {HeaderTextElement(0)}
      {HeaderTextElement(1)}

      {/* greadient overlay */}
      <div className="absolute sm:bottom-0 bottom-[-48px] w-full h-2/3 bg-gradient-to-t from-black to-transparent z-10 rounded-md opacity-70"></div>

      {/* carousel */}
      <Carousel
        theme={{
          root: { leftControl: "hidden", rightControl: "hidden" },
        }}
        indicators={false}
        className="rounded-none w-full h-full"
        onSlideChange={handleSlideChange}
      >
        <CaroudelImage src={sallyImg} alt={"sally"} />
        <CaroudelImage src={dannyImg} alt={"danny"} />
      </Carousel>
    </div>
  );
}
