import React from "react";

function Select({ title, options, name, onChange }) {
	return (
		<select
			className='select bg-white mb-4 px-3 py-[12px] block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-secondary sm:text-sm sm:leading-6 outline-none'
			name={name}
			onChange={onChange}
		>
			{options.map((op, idx) => (
				<option className="text-black" key={idx}>{op}</option>
			))}
		</select>
	);
}

export default Select;
