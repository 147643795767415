import React, { useState } from "react";
import Logo from "./icons/Logo";
import AudioPlayer from "./AudioPlayer";
import HeaderCarousel from "./HeaderCarousel";

function Header() {
   const [playAudio, setPlayAudio] = useState(false);
   const togglePlay = () => setPlayAudio(!playAudio);

   return (
      <header className="relative w-full h-[100vh] max-h-[900px] flex flex-col bg-gradient-to-r from-[#581c87] to-[#312e81]">
         <div className="header-bg"></div>
         <span className="flex max-w-fit z-50 pl-10 pb-5 md:pb-10 pt-3 md:pt-5">
            <Logo size={130} className="bg-white" />
         </span>
         <HeaderCarousel playAudio={playAudio} togglePlay={togglePlay} />
         <AudioPlayer
            playAudio={playAudio}
            togglePlay={togglePlay}
            setPlayAudio={setPlayAudio}
         />
      </header>
   );
}

export default Header;
