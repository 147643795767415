import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { setDoc, doc, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
   apiKey: "AIzaSyACQojKoZGfy0XlzAQoS1rKJHcEaP0mWYY",
   authDomain: "aitop40-stripe.firebaseapp.com",
   projectId: "aitop40-stripe",
   storageBucket: "aitop40-stripe.appspot.com",
   messagingSenderId: "321789211324",
   appId: "1:321789211324:web:1cf95e454ccf246b59d6b9",
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const auth = getAuth(app);
export const db = getFirestore(app);
