import React from "react";
import websiteLogo from "../../assets/logos/logo.svg";

function Logo({ size }) {
  return (
    <img className="aspect-square w-28 sm:w-36" src={websiteLogo} alt="logo" />
  );
}

export default Logo;
