// src/hooks/useAuth.ts
import {useState, useEffect} from "react";
import {onAuthStateChanged, getAuth} from "firebase/auth";
import {auth, db} from "../utils/firebase";
import {doc, getDoc, onSnapshot, updateDoc} from "firebase/firestore";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paid, setPaid] = useState(false);
  const [loadingPaid, setLoadingPaid] = useState(false);

  useEffect(() => {
    if (!auth.currentUser) return;
    auth.currentUser.getIdTokenResult().then(res => {
      setPaid(res.claims.paid);
    });
  }, []);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setUser(user);
      setLoading(false);
      if (!user) {
        setPaid(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (!user) return;
    setLoadingPaid(true);
    const docRef = doc(db, "users", user.uid);
    getDoc(docRef).then(doc => {
      if (doc.exists()) {
        const data = doc.data();
        console.log("data", data);
        setLoadingPaid(false);
        setPaid(Boolean(data.paid));
      }
    });
    const unsub = onSnapshot(doc(db, "users", user.uid), doc => {
      if (doc.exists()) {
        const data = doc.data();
        setPaid(Boolean(data.paid));
      }
    });
    return () => unsub();
  }, [user]);

  const updateDocument = async (user, data) => {
    const docRef = doc(db, "users", user?.uid);

    try {
      await updateDoc(docRef, data);
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return {user, loading, setLoading, paid, loadingPaid, updateDocument};
};

export default useAuth;
