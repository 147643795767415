import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ClientArea from "./pages/ClientArea";
import Success from "./pages/Success";
// import OurService from "./components/OurService";
// import Programs from "./components/Programs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home}></Route>
        <Route path="/login" Component={Login}></Route>
        <Route path="/signup" Component={Register}></Route>
        <Route path="/download" Component={ClientArea}></Route>
        <Route path="/success" Component={Success}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
