import {doc, getDoc, setDoc} from "firebase/firestore";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeaderText from "../components/HeaderText";
import Live from "../components/Live";

import Navbar from "../components/Navbar";
import Register from "./Register";
import {db} from "../utils/firebase";
import {useEffect} from "react";

function Home() {
  useEffect(() => {
    const initFunc = async () => {
      console.log("set doc caleed");

      const docRef = doc(db, "transactions", "vWqjeUIhNS5xADzr7dbV");
      await setDoc(doc(db, "transactions", "LA"), {
        name: "Los Angeles",
      });
      console.log(docRef, "doc ref");
      // const docSnap = await getDoc(docRef);
      // console.log(docSnap, "doc snap");

      // if (docSnap.exists()) {
      //    console.log("Document data:", docSnap.data());
      // } else {
      //    // docSnap.data() will be undefined in this case
      //    console.log("No such document!");
      // }
    };
    initFunc();
  }, []);
  return (
    <div className="bg-theme-primary text-lg">
      <Navbar></Navbar>
      <Header />
      <Live />
      <HeaderText />
      {/* <OurService />
         <Programs /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
